import React, { useContext, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import Modal from "../../components/modal";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../styles/main.css";
import Jure from "../../assets/jure.jpg";
import Kaja from "../../assets/kaja.jpg";
import X_sign from "../../assets/X_d.png";
import Second_Img from "../../assets/SecondImg.png";
import Navbar from "../../components/navbar";
import Timer from "../../components/timer";
import Footer from "../../components/footer";
import { UserContext } from "../../App";
import { translations } from "../../languages/langs";

const Main = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const { language, setLanguage } = useContext(UserContext);

  const getTranslation = (lang: string, key: string) => {
    // console.log(translations[lang][key]);
    return translations[lang][key];
  };

  useEffect(() => {
    open();
  }, []);

  return (
    <>
      <div className="container-custom">
        <Navbar
          buttonText={getTranslation(language, "open-poll")}
          to="/vprasalnik"
        />
        <div className="container-xxl px-0">
          <div className="mainImageContainer">
            <div className="mainImageContainerContent">
              <h1 className="title is-1 is-size-1-mobile has-text-centered pt-3 has-text-white title-text">
                Kaja &amp; Jure
              </h1>
              {/* <p className="title is-4-desktop is-size-5-mobile has-text-centered has-text-white subtitle-text">
                            Rezervirajte datum za najino poroko
                        </p> */}
              <p className="title is-2 is-size-3-mobile has-text-centered has-text-white title-text">
                {getTranslation(language, "wedding-date")}
              </p>
              <div className="has-text-white has-text-centered is-size-3 is-size-2-mobile ">
                {/* 14 DNI 12 UR 34 MIN */}
                <Timer
                  text={getTranslation(language, "wedding-countdown")}
                  textDone={getTranslation(language, "wedding-completed")}
                />
              </div>
              <div>
                <br />
                <br />
              </div>
            </div>
          </div>
          <div className="d-block mobile-people-container">
            <div className="row px-5 pb-3 pt-4">
              <div className="col-5 d-flex justify-content-center align-items-center">
                <img
                  src={Jure}
                  className="img-fluid avatar-pic rounded-circle"
                  alt="Jure"
                />
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center">
                <img src={X_sign} className="x-sign" alt="Divider" />
              </div>
              <div className="col-5 d-flex justify-content-center align-items-center">
                <img
                  src={Kaja}
                  className="avatar-pic rounded-circle"
                  alt="Kaja"
                />
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }} className="mt-5 mb-5">
            <img src={Second_Img} alt="Second" />
            <p className="second-img">
              After all this time.
              <br />
              Always.
            </p>
          </div>

          <div className="top-banner"></div>
          <div className="dogajanje-container">
            <div>
              <p className="h1 pt-6 d-flex justify-content-center">
                {getTranslation(language, "wedding-events")}
              </p>
            </div>

            <div className="d-flex py-5 justify-content-center">
              <div className="col-12 col-md-8 pb-5 d-flex flex-column justify-content-center">
                <div className="text-center">
                  <a
                    href="https://goo.gl/maps/UbnsAQCbAUpmKGBy5"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="batn mt-4 mb-4 d-inline-flex align-items-center"
                  >
                    <div>
                      {getTranslation(language, "location1-directions")}{" "}
                    </div>
                  </a>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event0")}</div>
                    <b>11:00</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event1")}</div>
                    <b>12:30</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event2")}</div>
                    <b>13:00</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event3")}</div> <b>14:50</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "photoshoot")}</div>{" "}
                    <b>15:00</b>
                  </div>

                  <a
                    href="https://goo.gl/maps/fwF7vLvRsCUh54UQ8"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="batn mb-4 mt-6 d-inline-flex align-items-center"
                  >
                    {getTranslation(language, "location2-directions")}
                  </a>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event4")}</div> <b>15:25</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event5")}</div> <b>15:55</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event6")}</div> <b>16:00</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event7")}</div> <b>16:30</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "congratulations")}</div>{" "}
                    <b>16:45</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "congratulations-2")}</div>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event8")}</div> <b>17:45</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event9")}</div> <b>19:15</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "first-dance")}</div>{" "}
                    <b>20:00</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event10")}</div>{" "}
                    <b>00:00</b>
                  </div>
                  <div className="d-flex justify-content-between event-item">
                    <div>{getTranslation(language, "event11")}</div>{" "}
                    <b>00:30</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-banner"></div>

          <AnimatePresence
            initial={false}
            exitBeforeEnter={true}
            onExitComplete={() => null}
          >
            {modalOpen && (
              <Modal
                text={getTranslation(language, "modal-title")}
                handleClose={close}
              />
            )}
          </AnimatePresence>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Main;
