import { FC, useEffect, useState } from "react";
import "../../styles/timer.css";

interface Props {
  textDone: string;
  text: string;
}

const Timer: FC<Props> = ({ textDone, text }) => {
  const [time, setTime] = useState("0d 0h 0m 0s");
  const [distance, setDistance] = useState(0);
  let countDownDate = new Date("June 17, 2023 12:30:00").getTime();

  // Update the count down every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let dist = countDownDate - now;
      setDistance(dist);
      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(dist / (1000 * 60 * 60 * 24));
      let hours = Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((dist % (1000 * 60)) / 1000);

      if (dist < 0) {
        setTime("");
        return () => clearInterval(interval);
      }
      let t = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      setTime(t);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timer-container">
      {distance < 0 ? (
        <>
          <h1 className="has-text-white">{textDone}</h1>
          <p className="text-white" id="countDown">
            {time}
          </p>
        </>
      ) : (
        <h1 className="has-text-white">{text}</h1>
      )}
    </div>
  );
};

export default Timer;
