import { FC } from "react";
import Corner from "../../assets/Corner.png";
import "../../styles/corners.css";

interface Props {
    classN?: string;
}

const Corners: FC<Props> = ({ classN }) => {
    return (
        <div>
            <img src={Corner} alt="corner" className={`top-left-question ${classN}`} />
            <img src={Corner} alt="corner" className={`bottom-left-question ${classN}`} />
            <img src={Corner} alt="corner" className={`top-right-question ${classN}`} />
            <img src={Corner} alt="corner" className={`bottom-right-question ${classN}`} />
        </div>
    );
};

export default Corners;
