export const languages = [
    {
        name: "Slovenian",
        code: "sl",
    },
    {
        name: "English",
        code: "en",
    },
];
interface TranslationPhrase {
    [key: string]: string;
}

interface Translation {
    [key: string]: TranslationPhrase;
}
export const translations: Translation = {
    sl: {
        "open-poll": "Odpri vprašalnik",
        close: "Zapri okno",
        "modal-title": "Pozdravljeni!",
        "modal-text": "Vprašalnik je odprt",
        "modal-text2": "Poročila se bova 17. junija!",
        "modal-text3": "Prosiva vas, da izpolnete obrazec, saj nam boste olajšali delo pri organizaciji.",
        "thanks-text": "Komaj čakava, da se vidimo na najinem dnevu.",
        "wedding-date": "17. junij 2023",
        "wedding-completed": "Poroka je že bila",
        "wedding-countdown": "Odštevalnik do poroke",
        "wedding-events": "POROČNO DOGAJANJE",
        event0: "Zbor svatov pri ženinu",
        event1: "Zbor svatov pred cerkvijo",
        event2: "Cerkvena poroka",
        event3: "Odhod svatov izpred cerkve",
        photoshoot: "Slikanje poročnega para",
        event4: "Prihod svatov na lokacijo",
        event5: "Prihod neveste na lokacijo",
        event6: "Civilna poroka",
        congratulations: "Čestitke,",
        "congratulations-2": "fotografiranje s parom in darila",
        event7: "Zdravica/šampanjec",
        event8: "Slikanje poročnega para",
        event9: "Večerja",
        "first-dance": "Prvi ples",
        event10: "Torta",
        event11: "Met šopka",
        "location1-directions": "Navodila za pot > Cerkev",
        "location2-directions": "Navodila za pot > Rozka",
        contact: "Kontakt",
        organizator: "Organizator poroke - Heidi",
        phonenumber: "040 592 242",
        back: "Nazaj",
        "form-success": "Hvala. Vaši odgovori so uspešno zabeleženi.",
        "form-error": "Prišlo je do napake. Preverite če ste pustili kakšno polje prazno.",
        "form-text1": "Prosimo, izpolnite vprašalnik. Najprej vnesite vaš email.",
        "form-text2": "Nato pa z vsakim klikom na gumb 'Dodaj osebo' dodate še enega udeleženca.",
        "form-email": "Potrdi",
        "invalid-email": "Neveljaven email",
        "form-remove-person": "Odstrani osebo",
        "form-add-person": "Dodaj osebo",
        "form-submit": "Oddaj odgovore",
        attending: "Se bom udeležil?",
        "attend-yes": "Z veseljem",
        "attend-no": "Žal me ne bo",
        location: "Pridružil/a se bom ob(na) …",
        location1: "11:00 zbor svatov pri ženinu",
        location2: "12:30 zbor svatov pred Cerkvijo",
        location3: "16:00 zbor svatov na lokaciji - Rozka",
        "meal-type": "Vrsta Prehrane",
        "meal-type1": "Ta domač (mesni)",
        "meal-type2": "Francoski (mesni)",
        "meal-type3": "Vegetarijanski meni (riba)",
        "meal-type4": "Veganski meni",
        sleeping: "Potrebujem prenočišče?",
        "sleeping-yes": "Da",
        "sleeping-no": "Ne",
        "breakfast-type": "Tip zajtrka",
        "breakfast-type1": "Zajtrk 8:00 - 10:00",
        "breakfast-type2": "Brunch skupaj z mladoporočencema in svati 11:30 (+ 8€)",
        "sleeping-info": "Nočitev z zajtrkom 35€/osebo",
        "meal-type1-menu": "Ta domač",
        "meal-type2-menu": "Francoski",
        "meal-type3-menu": "Riba/Vegi",
        "meal-type4-menu": "Veganski",
        "meal-welcome": "Pozdrav iz kuhinje",
        "meal-appetizer-cold": "Hladna predjed",
        "meal-appetizer-hot": "Topla predjed",
        "meal-salad": "Solata",
        "meal-main": "Glavna jed:",
        "meal-type1-food1": "Pražen krompir",
        "meal-type1-food2": "Štruklji (korenčkovi, drobnjak, skuta)",
        "meal-type1-food3": "Sezonska pečena zelenjava",
        "meal-type1-food4": "Ocvrta piščančja bedra (lizika)",
        "meal-type1-food5": "Goveji zrezki v čebulni omaki",
        "meal-type2-food1": "Goveji File",
        "meal-type2-food2": "Pire krompir s česnom in mlado špinačo",
        "meal-type2-food3": "Temna telečja omaka",
        "meal-type2-food4": "Opečene gobe krljaevio ostrigarji",
        "meal-type2-food5": "Karamelizirano korenje",
        "meal-type2-food6": "Ocvrta rdeča čebula",
        "meal-type2-food7": "Mikrozelenje luštreka",
        "meal-type3-food1": "Losos v skorjici iz semen, javorjevim sirupom",
        "meal-type3-food2": "Špinača",
        "meal-type3-food3": "Pire krompir s česnom in mlado špinačo",
        "meal-type3-food4": "Karamelizirano korenje",
        "meal-type3-food5": "Mikrozelenje",
        "meal-type4-food1": "Pire krompir s česnom in mlado špinačo",
        "meal-type4-food2": "Veganski zelenjavni polpet",
        "meal-type4-food3": "Opečene gobe krljaevio ostrigarji",
        "meal-type4-food4": "Karamelizirano korenje",
        "meal-type4-food5": "Ocvrta rdeča čebula",
        "meal-type4-food6": "Mikrozelenje luštreka",
        "show-meal-info": "Info",
        "hide-meal-info": "Skrij meni",
        menus: "Meniji",
    },
    en: {
        "open-poll": "Open form",
        close: "Close window",
        "modal-title": "Welcome!",
        "modal-text": "Form is open",
        "modal-text2": "We are getting married on the 17th of June 2023!",
        "modal-text3": "Please fill out the form, as it will help us with the organization.",
        "thanks-text": "We are looking forward to seeing you there.",
        "wedding-date": "17th of June 2023",
        "wedding-completed": "Wedding is already done",
        "wedding-countdown": "Wedding countdown",
        "wedding-events": "WEDDING EVENTS",
        event0: "Gathering of wedding guests at the groom's house",
        event1: "Gathering of wedding guests at the church",
        event2: "Church wedding",
        event3: "Departure of wedding guests",
        photoshoot: "Photo of the wedding couple    ",
        event4: "Arrival of wedding guests at the location",
        event5: "Arrival of bride",
        event6: "Civil wedding",
        congratulations: "Congratulations,",
        "congratulations-2": "photo of the wedding couple and gifts",
        event7: "Toast",
        event8: "Wedding photo",
        event9: "Dinner",
        "first-dance": "First dance",
        event10: "Cake",
        event11: "Bouquet toss",
        "location1-directions": "Directions > Church",
        "location2-directions": "Directions > Rozka",
        contact: "Contact",
        organizator: "Wedding organizer - Heidi",
        phonenumber: "00 386 40 592 242",
        back: "Back",
        "form-success": "Thank you. Your answers have been successfully recorded.",
        "form-error": "An error occurred. Please check if you left any fields blank.",
        "form-text1": "Please fill out the form. First enter your email.",
        "form-text2": "Then, with each click on the 'Add person' button, add another participant.",
        "form-email": "Confirm",
        "invalid-email": "Invalid email",
        "form-remove-person": "Remove person",
        "form-add-person": "Add person",
        "form-submit": "Submit answers",
        attending: "Will I attend?",
        "attend-yes": "With pleasure",
        "attend-no": "Unfortunately not",
        location: "I will join at …",
        location1: "11:00 gathering of wedding guests at the groom's",
        location2: "12:30 gathering of wedding guests in front of the Church",
        location3: "16:00 gathering of wedding guests at the location - Rozka",
        "meal-type": "Meal type",
        "meal-type1": "French menu (meat)",
        "meal-type2": "Farmers menu (meat)",
        "meal-type3": "Vegetarian menu (fish)",
        "meal-type4": "Vegan menu",
        sleeping: "Do I need accommodation?",
        "sleeping-yes": "Yes",
        "sleeping-no": "No",
        "breakfast-type": "Breakfast type",
        "breakfast-type1": "Breakfast 8:00 - 10:00",
        "breakfast-type2": "Brunch together with the newlyweds and guests at 12:30 (+ 8€)",
        "sleeping-info": "Accommodation with breakfast 35€/person",
        "meal-welcome": "Welcome meal",
        "meal-appetizer-cold": "Cold appetizer",
        "meal-appetizer-hot": "Hot appetizer",
        "meal-salad": "Salad",
        "meal-main": "Main course:",
        "meal-type1-food1": "Roasted potatoes",
        "meal-type1-food2": "Struklji (carrot, chives, cottage cheese)",
        "meal-type1-food3": "Seasonal roasted vegetables",
        "meal-type1-food4": "Fried chicken thighs (lollipop)",
        "meal-type1-food5": "Beef steaks in onion sauce",
        "meal-type2-food1": "Beef fillet",
        "meal-type2-food2": "Mashed potatoes with garlic and young spinach",
        "meal-type2-food3": "Dark veal sauce",
        "meal-type2-food4": "Roasted mushrooms",
        "meal-type2-food5": "Caramelized carrots",
        "meal-type2-food6": "Fried red onion",
        "meal-type2-food7": "Microgreens ",
        "meal-type3-food1": "Salmon in a crust of seeds, maple syrup",
        "meal-type3-food2": "Spinach",
        "meal-type3-food3": "Mashed potatoes with garlic and young spinach",
        "meal-type3-food4": "Caramelized carrots",
        "meal-type3-food5": "Microgreens",
        "meal-type4-food1": "Mashed potatoes with garlic and young spinach",
        "meal-type4-food2": "Vegan vegetable patties",
        "meal-type4-food3": "Roasted mushrooms",
        "meal-type4-food4": "Caramelized carrots",
        "meal-type4-food5": "Fried red onion",
        "meal-type4-food6": "Microgreens",
        "show-meal-info": "Info",
        "hide-meal-info": "Hide menu",
        menus: "Menus",
    },
};
