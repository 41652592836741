import { motion } from "framer-motion";
import { FC } from "react";
import "../../styles/backdrop.css";

interface Props {
    children: React.ReactNode;
    onClick: () => void;
}

const Backdrop: FC<Props> = ({ children, onClick }) => {
    return (
        <motion.div
            className="backdrop"
            onClick={onClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            {children}
        </motion.div>
    );
};

export default Backdrop;
