import React, { FC, useContext, useState } from "react";
import "../../styles/item.css";
import "../../../node_modules/bulma/css/bulma.min.css";
import Corner from "../../assets/Corner.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faInfo, faClose } from "@fortawesome/free-solid-svg-icons";
import Corners from "../corners";
import { translations } from "../../languages/langs";
import { UserContext } from "../../App";
interface IQuestionProps {
    onChange: (e: any, index: number) => void;
    primary: boolean;
    question: string;
    text: string;
    data: IQuestion;
    id: number;
    formErrors: any;
}

interface IQuestion {
    id: number;
    name: string;
    surname: string;
    email: string;
    location: string;
    attending: boolean;
    meal_type: string;
    sleeping: boolean;
    breakfast_option: string;
    primary: boolean;
}

const Question: FC<IQuestionProps> = ({ onChange, primary, question, text, data, formErrors, id }) => {
    const { language, setLanguage } = useContext(UserContext);
    const [showMeal, setShowMeal] = useState<boolean>(false);

    const getTranslation = (lang: string, key: string) => {
        return translations[lang][key];
    };
    return (
        <>
            <div className="card m-4">
                <div className="card-content card-contain card-styles">
                    <Corners />
                    <div className="form-container">
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field">
                                    {/* <label className="label">Name</label> */}
                                    <p className="control is-expanded has-icons-left">
                                        <input onChange={(e) => onChange(e, id)} className="input" type="text" placeholder="Ime" id={id.toString()} name="name" value={data.name} />
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon icon={faUser} />
                                        </span>
                                    </p>
                                    <div className="has-text-danger">{formErrors ? formErrors.name : ""}</div>
                                </div>
                                <div className="field">
                                    {/* <label className="label">Surname</label> */}
                                    <p className="control is-expanded has-icons-left">
                                        <input onChange={(e) => onChange(e, id)} name="surname" value={data.surname} className="input" type="text" placeholder="Priimek" />
                                        <span className="icon is-small is-left">
                                            <FontAwesomeIcon icon={faUser} />
                                        </span>
                                    </p>
                                    <div className="has-text-danger">{formErrors ? formErrors.surname : ""}</div>
                                </div>
                            </div>
                        </div>
                        <div className="field is-horizontal">
                            <div className="field-body">
                                <div className="field field-cont">
                                    <label className="label">{getTranslation(language, "attending")}</label>

                                    <div className="labels-container">
                                        <label className="radio">
                                            <input type="radio" onChange={(e) => onChange(e, id)} name={"_" + id + "_attending"} value={"true"} checked={data.attending} />
                                            {getTranslation(language, "attend-yes")}
                                        </label>
                                        <label className="radio">
                                            <input type="radio" onChange={(e) => onChange(e, id)} name={"_" + id + "_attending"} value={"false"} checked={!data.attending} />
                                            {getTranslation(language, "attend-no")}
                                        </label>
                                    </div>
                                    <div className="has-text-danger">{formErrors ? formErrors.attending : ""}</div>
                                </div>
                            </div>
                        </div>
                        {data.attending && (
                            <>
                                <div className="field is-horizontal">
                                    <div className="field-body">
                                        <div className="field">
                                            <label className="label">{getTranslation(language, "location")}</label>
                                            <div className="control">
                                                <div className="select">
                                                    {/* Fill the options from DB or hardcode? TODO */}
                                                    <select name="location" onChange={(e) => onChange(e, id)} value={data.location}>
                                                        <option value={"pri ženinu"}>{getTranslation(language, "location1")}</option>
                                                        <option value={"cerkev"}>{getTranslation(language, "location2")}</option>
                                                        <option value={"rozka"}>{getTranslation(language, "location3")}</option>
                                                    </select>
                                                </div>
                                                <div className="has-text-danger">{formErrors ? formErrors.location : ""}</div>
                                            </div>
                                        </div>
                                        <div className="field">
                                            <label className="label">{getTranslation(language, "meal-type")}</label>
                                            <div className="prehrana-container">
                                                <div className="control control-menu">
                                                    <div className="select">
                                                        {/* Fill the options from DB or hardcode? TODO */}
                                                        <select name="meal_type" onChange={(e) => onChange(e, id)} value={data.meal_type}>
                                                            <option value={"domac"}>{getTranslation(language, "meal-type1")}</option>
                                                            <option value={"francoski"}>{getTranslation(language, "meal-type2")}</option>
                                                            <option value={"vegi"}>{getTranslation(language, "meal-type3")}</option>
                                                            <option value={"vegan"}>{getTranslation(language, "meal-type4")}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="flex-container">
                                                    <button type="button" className="button custom-button show-menu-btn" onClick={() => setShowMeal(!showMeal)}>
                                                        {showMeal ? <FontAwesomeIcon icon={faClose} /> : getTranslation(language, "show-meal-info")}
                                                        {/* <FontAwesomeIcon icon={showMeal ? faClose : faInfo} /> */}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="has-text-danger">{formErrors ? formErrors.meal_type : ""}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="field is-horizontal">
                                    <div className="field-body">
                                        <div className="field">
                                            <label className="label sleeping-label">{getTranslation(language, "sleeping")}</label>
                                            <div className="sleeping-info">{getTranslation(language, "sleeping-info")}</div>
                                            <label className="radio">
                                                <input type="radio" name={"_" + id + "_sleeping"} onChange={(e) => onChange(e, id)} value={"true"} checked={data.sleeping} />
                                                {getTranslation(language, "sleeping-yes")}
                                            </label>
                                            <label className="radio">
                                                <input type="radio" name={"_" + id + "_sleeping"} onChange={(e) => onChange(e, id)} value={"false"} checked={!data.sleeping} />
                                                {getTranslation(language, "sleeping-no")}
                                            </label>
                                            <div className="has-text-danger">{formErrors ? formErrors.sleeping : ""}</div>
                                        </div>
                                        {data.sleeping && (
                                            <div className="field">
                                                <label className="label">{getTranslation(language, "breakfast-type")}</label>
                                                <label className="radio">
                                                    <input
                                                        type="radio"
                                                        name={"_" + id + "_breakfast_option"}
                                                        onChange={(e) => onChange(e, id)}
                                                        value={"breakfast"}
                                                        checked={data.breakfast_option === "breakfast"}
                                                    />
                                                    {getTranslation(language, "breakfast-type1")}
                                                </label>
                                                <label className="radio">
                                                    <input
                                                        type="radio"
                                                        name={"_" + id + "_breakfast_option"}
                                                        onChange={(e) => onChange(e, id)}
                                                        value={"brunch"}
                                                        checked={data.breakfast_option === "brunch"}
                                                    />
                                                    {getTranslation(language, "breakfast-type2")}
                                                </label>
                                                <div className="has-text-danger">{formErrors ? formErrors.breakfast_option : ""}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {showMeal && (
                <div className="card m-4">
                    <div className="card-content card-contain card-styles">
                        <Corners classN="height12" />
                        <div className="menu-title">{getTranslation(language, "menus")}</div>
                        <div className="form-container center menus">
                            <div className="mt-2">
                                <p className="meal-title">{getTranslation(language, "meal-type1")}</p>
                                <ul className="meal">
                                    <li>{getTranslation(language, "meal-welcome")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-cold")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-hot")}</li>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-main")}</li>
                                    <ul className="nested-list">
                                        <li>{getTranslation(language, "meal-type1-food1")}</li>
                                        <li>{getTranslation(language, "meal-type1-food2")}</li>
                                        <li>{getTranslation(language, "meal-type1-food3")}</li>
                                        <li>{getTranslation(language, "meal-type1-food4")}</li>
                                        <li>{getTranslation(language, "meal-type1-food5")}</li>
                                    </ul>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-salad")}</li>
                                </ul>
                            </div>
                            <div className="mt-2">
                                <p className="meal-title">{getTranslation(language, "meal-type2")}</p>
                                <ul className="meal">
                                    <li>{getTranslation(language, "meal-welcome")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-cold")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-hot")}</li>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-main")}</li>
                                    <ul className="nested-list">
                                        <li>{getTranslation(language, "meal-type2-food1")}</li>
                                        <li>{getTranslation(language, "meal-type2-food2")}</li>
                                        <li>{getTranslation(language, "meal-type2-food3")}</li>
                                        <li>{getTranslation(language, "meal-type2-food4")}</li>
                                        <li>{getTranslation(language, "meal-type2-food5")}</li>
                                        <li>{getTranslation(language, "meal-type2-food6")}</li>
                                        <li>{getTranslation(language, "meal-type2-food7")}</li>
                                    </ul>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-salad")}</li>
                                </ul>
                            </div>
                            <div className="mt-2">
                                <p className="meal-title">{getTranslation(language, "meal-type3")}</p>
                                <ul className="meal">
                                    <li>{getTranslation(language, "meal-welcome")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-cold")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-hot")}</li>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-main")}</li>
                                    <ul className="nested-list">
                                        <li>{getTranslation(language, "meal-type3-food1")}</li>
                                        <li>{getTranslation(language, "meal-type3-food2")}</li>
                                        <li>{getTranslation(language, "meal-type3-food3")}</li>
                                        <li>{getTranslation(language, "meal-type3-food4")}</li>
                                        <li>{getTranslation(language, "meal-type3-food5")}</li>
                                    </ul>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-salad")}</li>
                                </ul>
                            </div>
                            <div className="mt-2">
                                <p className="meal-title">{getTranslation(language, "meal-type4")}</p>
                                <ul className="meal">
                                    <li>{getTranslation(language, "meal-welcome")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-cold")}</li>
                                    <li>{getTranslation(language, "meal-appetizer-hot")}</li>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-main")}</li>
                                    <ul className="nested-list">
                                        <li>{getTranslation(language, "meal-type4-food1")}</li>
                                        <li>{getTranslation(language, "meal-type4-food2")}</li>
                                        <li>{getTranslation(language, "meal-type4-food3")}</li>
                                        <li>{getTranslation(language, "meal-type4-food4")}</li>
                                        <li>{getTranslation(language, "meal-type4-food5")}</li>
                                        <li>{getTranslation(language, "meal-type4-food6")}</li>
                                    </ul>
                                    {/* <br /> */}
                                    <li>{getTranslation(language, "meal-salad")}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="center menu-btn">
                            <button type="button" className="button custom-button show-menu-btn" onClick={() => setShowMeal(!showMeal)}>
                                {showMeal ? getTranslation(language, "hide-meal-info") : getTranslation(language, "show-meal-info")}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Question;
