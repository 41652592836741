import { FC, useContext } from "react";
import { Link } from "react-router-dom";
import "../../styles/navbar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../App";

interface Props {
    buttonText: string;
    to: string;
}

const Navbar: FC<Props> = ({ buttonText, to }) => {
    const { language, setLanguage } = useContext(UserContext);

    return (
        <div className="navbar-container">
            <div className="left">
                {language === "en" ? (
                    <button
                        className="navbar-button"
                        onClick={() => setLanguage("sl")}
                    >
                        🇸🇮
                    </button>
                ) : (
                    <button
                        className="navbar-button"
                        onClick={() => setLanguage("en")}
                    >
                        🇬🇧
                    </button>
                )}
            </div>

            <div className="center"></div>

            <div className="right">
                <Link
                    className="navbar-button"
                    style={{ color: "inherit" }}
                    to="/"
                >
                    <div>
                        <FontAwesomeIcon icon={faHome} />
                    </div>
                </Link>
                <Link
                    className="navbar-button"
                    style={{ color: "inherit" }}
                    to={to}
                >
                    {buttonText}
                </Link>
            </div>
        </div>
    );
};

export default Navbar;
