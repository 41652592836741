import React, { useContext, useEffect, useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../../styles/linkbutton.css";
import Question from "../../components/question";
import Navbar from "../../components/navbar";
import "../../styles/item.css";
import "../../styles/form.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Corners from "../../components/corners";
import { UserContext } from "../../App";
import { languages, translations } from "../../languages/langs";

interface IQuestion {
  id: number;
  name: string;
  surname: string;
  email: string;
  location: string;
  attending: boolean;
  meal_type: string;
  sleeping: boolean;
  breakfast_option: string;
  primary: boolean;
}

interface IQuestionErrors {
  id: number;
  name: string;
  surname: string;
  email: string;
  location: string;
  attending: string;
  meal_type: string;
  sleeping: string;
  breakfast_option: string;
}

const Form = () => {
  // Fetch from API
  // Example
  // Double loading answer -> https://stackoverflow.com/a/72406657
  // const [identifiers, setIdentifiers] = useState<IIdentifiers[]>([]);
  // TODO: Change from any type

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<IQuestionErrors[]>([]);
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [removedUsers, setRemovedUsers] = useState<IQuestion[]>([]);
  const [mailSubmitted, setMailSubmitted] = useState(false);
  const [users, setUsers] = useState<IQuestion[]>([
    {
      id: 0,
      name: "",
      surname: "",
      email: email,
      location: "pri ženinu",
      attending: true,
      meal_type: "francoski",
      sleeping: false,
      breakfast_option: "brunch",
      primary: true,
    },
  ]);

  const [allUsersLength, setAllUsersLength] = useState<number>(0);

  const [isSubmit, setIsSubmit] = useState(false);

  const { language, setLanguage } = useContext(UserContext);

  const getTranslation = (lang: string, key: string) => {
    return translations[lang][key];
  };

  useEffect(() => {
    // Get all users length
    const getAllUsersLength = async () => {
      const response = await fetch("https://kaja-in-jure.si/api/answers/");
      const data = await response.json();
      setAllUsersLength(data.length);
    };

    getAllUsersLength();
    // console.log("ALL USERS LENGTH", allUsersLength);
    if (formErrors.length === 0 && isSubmit && isEmailValid) {
      try {
        // console.log("USERS");

        // Post users
        // Log users
        // console.log(users);
        const resposne = fetch("https://kaja-in-jure.si/api/answers/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(users),
        }).then((res) => {
          // console.log("RESPONSE");
          // console.log(res);
        });

        // Delete removed users
        const deleteRemoved = async () => {
          removedUsers.forEach(async (user) => {
            // console.log("DELETING", user);
            fetch("https://kaja-in-jure.si/api/answers/" + user.id, {
              method: "DELETE",
              headers: { "Content-Type": "application/json" },
            }).then((res) => {
              // console.log(res);

              // Clear currently removed user
              setRemovedUsers(removedUsers.filter((u) => u.id !== user.id));
            });
          });
        };
        deleteRemoved();
        setIsSuccess(true);
        setEmail("");
        setMailSubmitted(false);
        setRemovedUsers([]);
        setUsers([]);
        // Clear all input fields
        const inputs = document.querySelectorAll("input");
        inputs.forEach((input) => {
          input.value = "";
        });
      } catch (error) {
        // console.log(error);
        setIsSuccess(false);
      } finally {
      }
    }
  }, [formErrors]);

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    setIsEmailValid(regex.test(email));
  };

  const validateAnswers = (user: IQuestion, userErrors: IQuestionErrors) => {
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (user.name === "" || !user.name.trim()) {
      userErrors.name = "Name is required";
    }
    if (user.surname === "" || !user.surname.trim()) {
      userErrors.surname = "Surname is required";
    }
    if (user.location === "") {
      userErrors.location = "Location is required";
    }
    if (user.attending !== true && user.attending !== false) {
      userErrors.attending = "Attending is required";
    }
    if (user.meal_type === "") {
      userErrors.meal_type = "Meal type is required";
    }
    if (user.sleeping === true) {
      if (user.breakfast_option === "") {
        userErrors.breakfast_option = "Breakfast option is required";
      }
    }
    if (user.sleeping !== true && user.sleeping !== false) {
      userErrors.sleeping = "Sleeping is required";
    }

    return userErrors;
  };

  const iterateUsersValidation = (users: IQuestion[]) => {
    let errors: IQuestionErrors[] = [];

    users.forEach((user, index) => {
      let userErrors: IQuestionErrors = {
        id: index,
        name: "",
        surname: "",
        email: "",
        location: "",
        attending: "",
        meal_type: "",
        sleeping: "",
        breakfast_option: "",
      };
      userErrors = validateAnswers(user, userErrors);
      // Print all values whose key is not id

      // console.log();
      // console.log(Object.values(userErrors).every((x) => x));
      if (
        Object.values(userErrors).filter(
          (value) => value !== "" && typeof value === "string"
        ).length > 0
      ) {
        errors.push(userErrors);
      } else {
        errors = [];
      }
    });
    // console.log(errors);
    return errors;
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    setFormErrors(iterateUsersValidation(users));
    setIsSubmit(true);
    setEmailToUsers();

    // if (formErrors.length === 0) {
    //     console.log("Form is valid");
    // }
  };

  // useEffect(() => {
  //     emailChangeHandler(email);
  // }, [email]);

  const emailChangeHandler = (e: any) => {
    // console.log(e.target.value);
    let email = e.target.value;
    validateEmail(email);
    setEmail(email);
  };

  const changeHandler = (e: any, index: number) => {
    let { name, value } = e.target;

    const newUsers = users.map((user: IQuestion, i: number) => {
      if (i === index) {
        let usr = {};
        if (name.indexOf("_") === 0) {
          const index = name.indexOf("_", 1);
          name = name.substring(index + 1);
        }

        if (value === "true" || value === "false") {
          usr = {
            ...user,
            [name]: value === "true" ? true : false,
          };
        } else {
          usr = { ...user, [name]: value };
        }
        return usr;
      }
      return user;
    });

    setUsers(newUsers as IQuestion[]);
  };

  const addFieldHandler = () => {
    let lastId = 0;
    if (users.length > 0) {
      lastId = users[users.length - 1].id;
    }

    const newUser = {
      // Set id random to 200 to 1000
      id: Math.floor(Math.random() * (1000 - 200 + 1) + 200),
      name: "",
      surname: "",
      email: "",
      location: "pri ženinu",
      attending: true,
      meal_type: "francoski",
      sleeping: false,
      breakfast_option: "brunch",
      primary: false,
    };
    if (users.length === 0) {
      newUser.primary = true;
    }
    // console.log(newUser);
    // console.log(users);
    setAllUsersLength(allUsersLength + 1);
    setUsers([...users, newUser]);
  };

  const removeFieldHandler = (index: number) => {
    const values = [...users];
    let userIx = values.findIndex((val) => val.id === index);
    let removedUser = users[userIx];
    removedUser.email = users[0].email;
    // console.log(removedUser);
    setRemovedUsers([...removedUsers, removedUser]);
    values.splice(
      values.findIndex((val) => val.id === index),
      1
    );
    // console.log(values);
    setAllUsersLength(allUsersLength - 1);
    setUsers(values);
  };

  const setEmailToUsers = () => {
    const newUsers = users.map((user: IQuestion, i: number) => {
      let usr = { ...user, email: email };
      return usr;
    });
    setUsers(newUsers as IQuestion[]);
  };

  const emailChecker = async () => {
    if (email === "" || email === undefined) {
      setMailSubmitted(false);
      return;
    }
    validateEmail(email);
    setIsSubmit(false);

    if (!isEmailValid) {
      setMailSubmitted(false);
    } else if (isEmailValid) {
      setMailSubmitted(true);
      setRemovedUsers([]);

      // Check if email is in database
      // If it is, fetch data and fill in the form

      let response = await fetch(
        "https://kaja-in-jure.si/api/answers/" + email,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      ).then((response) => response.json());

      // console.log(response);

      let users: IQuestion[] = (await response) as IQuestion[];
      setUsers((await response) as IQuestion[]);
      if (users.length === 0) {
        users = [
          {
            id: 0,
            name: "",
            surname: "",
            email: "",
            location: "pri ženinu",
            attending: true,
            meal_type: "francoski",
            sleeping: false,
            breakfast_option: "brunch",
            primary: true,
          },
        ];
      }
      // console.log(users);

      setUsers(users);
    }
  };

  return (
    <div className="main-container">
      <Navbar buttonText={getTranslation(language, "back")} to="/" />
      {/* <h1 className="title is-1 center-text mt-3">Vprašalnik</h1> */}

      {isSubmit && (
        <div className="m-4">
          {isSuccess && (
            <div className="card-content card-contain h5 bg-success text-white">
              {getTranslation(language, "form-success")}
            </div>
          )}
          {!isSuccess && (
            <div className="card-content card-contain h5 bg-danger text-white">
              <p>{getTranslation(language, "form-error")}</p>
            </div>
          )}
        </div>
      )}

      <div className="card m-4">
        <div className="card-content card-contain card-styles">
          <Corners />
          <div className="form-container">
            <div className="field">
              <p className="mb-2">{getTranslation(language, "form-text1")}</p>
              <p>{getTranslation(language, "form-text2")}</p>
              <p className="control is-expanded has-icons-left has-icons-right">
                <input
                  className="input"
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={(e) => emailChangeHandler(e)}
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </p>
            </div>
            <div className="submit-container">
              <button
                type="button"
                className="button custom-button"
                onClick={() => emailChecker()}
              >
                {getTranslation(language, "form-email")}
              </button>
              {!isEmailValid && (
                <div>{getTranslation(language, "invalid-email")}</div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="card m-4">
                <div className="card-content card-contain card-styles">
                    <Corners classN={showMeal ? "height12" : "height40"} />
                    <div className="form-container">
                        <div className="field">Za prikaz menijev, kliknite gumb</div>
                        <button type="button" className="button custom-button" onClick={() => setShowMeal(!showMeal)}>
                            {showMeal ? getTranslation(language, "hide-meal-info") : getTranslation(language, "show-meal-info")}
                        </button>
                        {showMeal && (
                            <div>
                                <div className="mt-2">
                                    <p className="meal-title">{getTranslation(language, "meal-type1")}</p>
                                    <ul className="meal">
                                        <li>{getTranslation(language, "meal-type1-food1")}</li>
                                        <li>{getTranslation(language, "meal-type1-food2")}</li>
                                        <li>{getTranslation(language, "meal-type1-food3")}</li>
                                        <li>{getTranslation(language, "meal-type1-food4")}</li>
                                        <li>{getTranslation(language, "meal-type1-food5")}</li>
                                    </ul>
                                </div>
                                <div className="mt-2">
                                    <p className="meal-title">{getTranslation(language, "meal-type2")}</p>
                                    <ul className="meal">
                                        <li>{getTranslation(language, "meal-type2-food1")}</li>
                                        <li>{getTranslation(language, "meal-type2-food2")}</li>
                                        <li>{getTranslation(language, "meal-type2-food3")}</li>
                                        <li>{getTranslation(language, "meal-type2-food4")}</li>
                                        <li>{getTranslation(language, "meal-type2-food5")}</li>
                                        <li>{getTranslation(language, "meal-type2-food6")}</li>
                                        <li>{getTranslation(language, "meal-type2-food7")}</li>
                                    </ul>
                                </div>
                                <div className="mt-2">
                                    <p className="meal-title">{getTranslation(language, "meal-type3")}</p>
                                    <ul className="meal">
                                        <li>{getTranslation(language, "meal-type3-food1")}</li>
                                        <li>{getTranslation(language, "meal-type3-food2")}</li>
                                        <li>{getTranslation(language, "meal-type3-food3")}</li>
                                        <li>{getTranslation(language, "meal-type3-food4")}</li>
                                        <li>{getTranslation(language, "meal-type3-food5")}</li>
                                    </ul>
                                </div>
                                <div className="mt-2">
                                    <p className="meal-title">{getTranslation(language, "meal-type4")}</p>
                                    <ul className="meal">
                                        <li>{getTranslation(language, "meal-type4-food1")}</li>
                                        <li>{getTranslation(language, "meal-type4-food2")}</li>
                                        <li>{getTranslation(language, "meal-type4-food3")}</li>
                                        <li>{getTranslation(language, "meal-type4-food4")}</li>
                                        <li>{getTranslation(language, "meal-type4-food5")}</li>
                                        <li>{getTranslation(language, "meal-type4-food6")}</li>
                                    </ul>
                                </div>
                                <button type="button" className="button custom-button" onClick={() => setShowMeal(!showMeal)}>
                                    {showMeal ? getTranslation(language, "hide-meal-info") : getTranslation(language, "show-meal-info")}
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div> */}

      {mailSubmitted && (
        <form onSubmit={submitHandler}>
          {users.map((user, index) => {
            return (
              <div key={index}>
                <Question
                  onChange={changeHandler}
                  primary={user.primary}
                  question={"Vprasanje " + (index + 1)}
                  text={user.name + " " + user.surname}
                  data={user}
                  formErrors={
                    formErrors.filter((error) => error.id === index)[0]
                  }
                  id={index}
                />
                {users.length > 1 && (
                  <button
                    type="button"
                    className="button is-danger ml-4"
                    onClick={() => removeFieldHandler(user.id)}
                  >
                    {getTranslation(language, "form-remove-person")}
                  </button>
                )}
              </div>
            );
          })}
          <div className="bottom-buttons">
            {users.length < 5 && (
              <div className="">
                <button
                  type="button"
                  className="button custom-button-outlined ml-4"
                  id="add_user"
                  onClick={() => addFieldHandler()}
                >
                  {getTranslation(language, "form-add-person")}
                </button>
              </div>
            )}
            <div className="submit-container mb-5 ml-4">
              <button
                className="button is-outlined custom-button"
                onClick={(e: any) => submitHandler(e)}
              >
                {getTranslation(language, "form-submit")}
              </button>
            </div>
          </div>
        </form>
      )}
      {isSubmit && (
        <div className="m-4">
          {isSuccess && (
            <div className="card-content card-contain h5 bg-success text-white">
              {getTranslation(language, "form-success")}
            </div>
          )}
          {!isSuccess && (
            <div className="card-content card-contain h5 bg-danger text-white">
              <p>{getTranslation(language, "form-error")}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Form;
