import { motion } from "framer-motion";
import { FC, useContext } from "react";
import Backdrop from "../backdrop";
import "../../styles/modal.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Corner from "../../assets/Corner.png";
import { UserContext } from "../../App";
import { translations } from "../../languages/langs";
interface Props {
    handleClose: () => void;
    text: string;
}

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.5,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        y: "100vh",
        opacity: 0,
        transition: {
            duration: 0.5,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
};

const Modal: FC<Props> = ({ handleClose, text }) => {
    const { language, setLanguage } = useContext(UserContext);

    const getTranslation = (lang: string, key: string) => {
        return translations[lang][key];
    };

    const navigate = useNavigate();

    const goToForm = () => {
        navigate("/vprasalnik");
    };

    return (
        <Backdrop onClick={handleClose}>
            <motion.div onClick={(e) => e.stopPropagation()} className="modal orange-gradient" variants={dropIn} initial="hidden" animate="visible" exit="exit">
                <img src={Corner} alt="corner" className="top-left" />
                <img src={Corner} alt="corner" className="bottom-left" />
                <img src={Corner} alt="corner" className="top-right" />
                <img src={Corner} alt="corner" className="bottom-right" />
                <ModalText text={text} text2={getTranslation(language, "modal-text2")} text3={getTranslation(language, "modal-text3")} textThanks={getTranslation(language, "thanks-text")} />
                <div className="button-div">
                    {language === "en" ? (
                        <button className="modal-button" onClick={() => setLanguage("sl")}>
                            🇸🇮 Slo
                        </button>
                    ) : (
                        <button className="modal-button" onClick={() => setLanguage("en")}>
                            🇬🇧 Eng
                        </button>
                    )}
                    {/* OnClick Go To Form... Or just open on same page as modal */}
                    {/* Od ModalButton onClick go to /vprasalnik*/}
                    <ModalButton
                        // Navigate to form
                        onClick={goToForm}
                        object={
                            <Link style={{ color: "inherit" }} to="/vprasalnik">
                                {getTranslation(language, "open-poll")}
                            </Link>
                        }
                    />
                    <ModalButton object={getTranslation(language, "close")} onClick={handleClose} />
                </div>
            </motion.div>
        </Backdrop>
    );
};

interface IModalTextProps {
    text: string;
    text2: string;
    text3: string;
    textThanks: string;
}

const ModalText: FC<IModalTextProps> = ({ text, text2, text3, textThanks }) => (
    <div className="modal-text">
        <h3>{text}</h3>
        <p>{text2}</p>
        <p>{text3}</p>
        <p>{textThanks}</p>
    </div>
);

interface IModalButtonProps {
    onClick?: () => void;
    object: any;
    class?: string;
}

export const ModalButton: FC<IModalButtonProps> = ({ onClick, object }) => (
    <motion.button className="modal-button" type="button" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} onClick={onClick}>
        {object}
    </motion.button>
);
export default Modal;
