import {
  faHome,
  faPen,
  faPencil,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import { translations } from "../../languages/langs";
import "../../styles/footer.scss";

const Footer = () => {
  const { language, setLanguage } = useContext(UserContext);

  const getTranslation = (lang: string, key: string) => {
    return translations[lang][key];
  };
  return (
    <div className="footer">
      <div className="vprasalnik-button-container">
        <Link
          className="button-c"
          style={{ color: "inherit" }}
          to="/vprasalnik"
        >
          {getTranslation(language, "open-poll")}
        </Link>
      </div>
      <div className="footer-header">{getTranslation(language, "contact")}</div>
      <div className="footer-items">
        <div className="footer-item">
          <div>{getTranslation(language, "organizator")}</div>
          <a href="tel:00386 40 592 242">
            {getTranslation(language, "phonenumber")}
          </a>
          <a
            href="mailto:pocukran.dan@gmail.com"
            className="email"
            target="_blank"
            rel="noreferrer"
          >
            pocukran.dan@gmail.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
