import React, { useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Form from "./screens/Form";
import Main from "./screens/Main";

export const UserContext = React.createContext({
    language: "sl",
    setLanguage: (language: string) => {},
});

function App() {
    const [language, setLanguage] = useState("sl");

    return (
        <Router>
            <UserContext.Provider value={{ language, setLanguage }}>
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/vprasalnik" element={<Form />} />
                </Routes>
            </UserContext.Provider>
        </Router>
    );
}

export default App;
